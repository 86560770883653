@use "../core/fonts";

.request-detail {
	&__information {
		display: flex;
		flex-wrap: wrap;
		gap: 22px;
		padding-bottom: 16px;

		.input-container {
			width: 290px;

			input[type="text"], input[type="date"] {
				width: 100%;

				@extend .inputs-md;
				@extend .fw400;
				color: var(--theme-text-primary);
			}
		}
	}

	&__type {
		min-width: 290px;

		p{
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            color: var(--theme-text-secondary);

            @extend .fw500;
        }

		span{
			@extend .typhography-sm;
			@extend .fw500;
		}
	}

	&__items {
		width: 25%;
		padding: 1rem 0rem;
	}
}
