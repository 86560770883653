@use "../core/fonts";
.login-form {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 90%;

	&__welcome{
		flex: 1;
		line-height: 2rem;
		color: #fff;
		&__title{
			font-size: 44px;
			line-height: 58px;
		}
	}
	&__login {
		max-width: 500px;
		display: flex;
		flex-direction: column;
		flex: 1;
		background-color: #fff;
		padding: 2rem;
		border-radius: 16px;

		input{
			width: calc(100% - 32px)
		}
	}
	&__register {
		display: flex;
		flex-direction: column;
		background-color: #fff;
		padding: 3rem;
		border-radius: 16px;
		max-width: 560px;

		.input-content {
			input[type=text], select {
				border: unset;
			}

			select{
				background-color: var(--brand-neutral-100);
			}
		
		}

		.row-input {
			display: flex;
			gap: 16px;

			.input-content {
				width: 50%;

				input {
					max-width: 13rem;
				}
			}
		}

		.send {
			align-self: center;
			button {
				width: 508px;
				.button-component {
					width: 460px;
					justify-content: center;
					border-radius: 30px;
				}
			}
		}

		&__container{
			overflow-y: scroll;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding:0.8rem
		}
		
	}
	.calltoaction{
		display: flex;
		flex-direction: column;
		color: #fff;
		margin-right: 4rem;
		align-items: flex-start;

		&__title{
			font-size: 44px;
			line-height: 58px;
			margin-bottom: 4rem;
		}

		&__list{
			display: flex;
			gap: 16px;
			align-items: center;
			padding: 1rem 0rem;
			p{
				font-size: 24px;
			}
		}
		&__icon{
			&--flash{
				width: 3rem;
				height: 3rem;
				background-image: url("/assets/images/icons/icon-flash.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}

			&--crosshair{
				width: 3rem;
				height: 3rem;
				background-image: url("/assets/images/icons/icon-crosshair.svg");
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
	&__forgot-password {
		display: flex;
		flex-direction: column;
		width: 460px;
		background-color: #fff;
		padding: 3rem;
		border-radius: 16px;
		.form-content {
			padding-top: 24px;

			form {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 24px;

				.input-content {
					input {
						max-width: 422px;
					}
				}
			}

			&__send {
				width: 100%;
				color: var(--theme-common-white);
				background-color: var(--brand-tertiary-500);
				text-align: center;
				padding: 14px 0;
				border-radius: 40px;
				cursor: pointer;
			}

			.login-form__error{
				padding-top: 6px;
				text-align: left;
			}
		}
	}

	&__addpassword{
		background-color: #fff;
		padding: 3rem;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__title{
			padding: 1rem 0rem;
		}
	}

	&__title {
		font-size: 40px;
		line-height: 24px;
		padding-top: 2rem;
		color: var(--brand-secondary-900);
	}

	&__subtitle {
		text-align: center;
		padding-top: 56px;
	}

	&__redirect {
		padding-top: 24px;

		@extend .typhography-sm;
		color: var(--brand-secondary-700);

		span {
			cursor: pointer;
			color: var(--brand-tertiary-500);
		}
	}

	&__error{
		color: var(--danger-500);
		text-align: center;
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		span{
			background-image: url("/assets/images/icons/icon-error.svg");
			background-repeat: no-repeat;
			background-position: 10px;
			background-size: 24px;
			padding-left: 2.5em;
		}
	}


	&__button{
		width: 100%;
		color: var(--theme-common-white);
		background-color: var(--brand-tertiary-500);
		padding: 14px 0;
		margin-top: 12px;
		border-radius: 100px;
	}

	.error-text{
		color: var(--danger-500);
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		padding-top: 6px;
		span{
			background-image: url("/assets/images/icons/icon-error.svg");
			background-repeat: no-repeat;
			background-position: 0;
			background-size: 24px;
			padding-left: 2em;
		}
	}

}

.form-content {
	padding-top: 55px;
	width: 100%;
	form {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	input {
		
		padding: 12px 16px;
		border: unset;
		background-color: var(--brand-neutral-100);
		border-radius: 2px;
	}

	.input-content {
		width: 100%;
		label {
			input {
				max-width: calc(100% - 40px);
			}
		}

		select {
			width: 100%;
		}

		&__checkbox {
			p {
				span {
					color: var(--brand-tertiary-500);
					cursor: pointer;
				}

				a{
					color: var(--brand-tertiary-500);
					text-decoration: unset;
				}
			}
		}
	}

	&__row-input {
		display: flex;
		gap: 16px;

		.input-content {
			width: 50%;
		}
	}

	&__create-account{
		padding-top: 24px;
		font-size: 14px;
		font-weight: 500;
		line-height: 21px;
		text-align: center;
		color: var(--theme-text-secondary);

		span{
			color: var(--brand-tertiary-500);
			cursor: pointer;
		}
	}
}

.input-wrapper {
	&__title {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
		font-weight: 500;
		line-height: 21px;
		color: var(--theme-text-secondary);

		&__forgot {
			cursor: pointer;
			font-weight: 400;
			font-size: 14px;
			line-height: 19px;
			color: var(--brand-tertiary-500);
		}
	}
}

input[type="password"] {

	label {
		input {
			max-width: 476px;
		}
	}
}

.congrats-message {
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	text-align: center;
	max-width: 572px;
	background-color: #fff;
	padding: 3rem;
	border-radius: 16px;

	&__title {
		font-size: 40px;
		line-height: 54px;
		padding-top: 60px;
		color: var(--brand-tertiary-500);
	}

	&__subtitle{
		width: 100% !important;
	}
}

.password-created {
	padding-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	text-align: center;
	max-width: 572px;

	&__title {
		font-size: 40px;
		line-height: 54px;
		padding-top: 60px;
		color: var(--brand-tertiary-500);
	}
}

.terms-conditions {
	&__header {
		background-color: var(--brand-neutral-100);
		h3 {
			padding: 32px;
			color: var(--theme-text-primary);
		}
	}

	&__text {
		border-bottom: 1px solid var(--brand-neutral-200);
		p {
			padding: 32px;
			@extend .p-md;
			@extend .fw500;
			color: var(--theme-text-primary);

			span {
				@extend .fw700;
			}
		}
	}

	&__button {
		padding: 32px;
	}
}

.password-form{

	p{
		@extend .inputs-md;
		font-weight: 400;
	}

	&__legal-input{
		max-width: 508px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 16px 0;
		margin-bottom: 24px;
		border-top: 1px solid var(--brand-neutral-200);

		span{
			color: var(--brand-tertiary-500);
			cursor: pointer;
		}
	}

}