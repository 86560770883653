@use "../core/fonts";

.header {
	&--logged {
		position: fixed;
		width: 100vw;
		z-index: 100;
		&__container {
			padding: 16px 40px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			background-color: var(--theme-common-white);

			border-bottom: 1px solid var(--brand-secondary-200);
		}
		&__logo {
			max-width: 295px;

			a {
				display: block;
				height: 100%;
			}
		}
		&__buttons {
			display: flex;
			align-items: center;
			gap: 32px;
			&__links {
				display: flex;
				gap: 16px;
				max-height: 40px;
				z-index: 100;
			}
			&__menu {
				display: flex;
			}
		}
		a {
			&.active {
				padding: 0.8rem;
				background-color: var(--brand-tertiary-050);
			}
		}
	}
}

.footer {
	border-top: 1px solid var(--brand-secondary-200);
	&__top {
		padding: 32px 0;
		margin: 0 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid var(--brand-secondary-200);

		&__copyright {
			@extend .menu-item-md;
			@extend .fw700;
			padding-right: 30px;
		}

		&__links {
			display: flex;
			gap: 16px;
		}
	}

	&__bottom {
		padding: 32px 96px;

		&__disclaimer {
			@extend .typhography-sm;
			@extend .fw500;
		}
	}
}

.body-container {
	width: 100%;
	min-height: 72vh;
	background-color: var(--brand-neutral-050);
	margin-bottom: 40px;
}

.wrapper {
	width: 1296px;
	max-width: 94vw;
	margin: 0 auto;
	padding-top: 86px;
}

.login-full-screen-layout {
	position: relative;
	margin: 0;
	height: 100vh;
	width: 100vw;
	background: linear-gradient(-121.01deg, #47D7AC -0.78%, #3366FF 55.83%, #13294B 112.45%);

	&__content {
		display: flex;
		padding: 40px 0;
		height: 90vh;
		gap: 22px;
		// align-items: center;
		@media (min-height: 1058px) {
			align-items: center;
		}
	}

	&__form {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-top: 8px;
		overflow-y: scroll;
		-ms-overflow-style: none; /* for Internet Explorer, Edge */
  		scrollbar-width: none;

		@media (min-height: 820px) {
			align-items: center;
		}

		&__content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: fit-content;
			width: 90%;
		}

		&__logo {
			width: 300px;
			height: 80px;
			background-size: contain;
			background-image: url("/assets/images/logo.svg");
			background-position: center;
			background-repeat: no-repeat;
			// width: 150px;
			// height: 58px;
			// background-size: cover;
			// background-image: url("/assets/images/logo-login.svg");
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		max-height: 880px;
		border-radius: 32px;
		background-position: center;
		background-size: cover;
		background-image: url("/assets/images/login-image.jpg");
	}
}
