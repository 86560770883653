@font-face {
	font-family: "Montserrat";
	src: url("/assets/fonts/montserrat/Montserrat-Regular.ttf") format("woff2");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("/assets/fonts/montserrat/Montserrat-Medium.ttf") format("woff2");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("/assets/fonts/montserrat/Montserrat-SemiBold.ttf") format("woff2");
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: "Montserrat";
	src: url("/assets/fonts/montserrat/Montserrat-Bold.ttf") format("woff2");
	font-weight: 700;
	font-style: normal;
}

body {
	font-family: "Montserrat";
	color: var(--theme-text-primary);
}

// FONT WEIGHTS

.fw400 {
	font-weight: 400;
}
.fw500 {
	font-weight: 500;
}
.fw600 {
	font-weight: 600;
}
.fw700 {
	font-weight: 700;
}

// TYPOGRAFÍA DE ITEMS
.typhography-sm {
	font-size: 14px;
	line-height: 20px;
}

.p-md {
	font-size: 16px;
	line-height: 22px;
}

.menu-item-sm,
.inputs-sm {
	font-size: 14px;
	line-height: 16px;
}

.menu-item-md,
.inputs-md {
	font-size: 16px;
	line-height: 20px;
}

.components-button-sm {
	font-size: 12px;
	line-height: 12px;
}

.components-button-md {
	font-size: 14px;
	line-height: 14px;
}

.components-button-lg {
	font-size: 16px;
	line-height: 18px;
}

.text-xl {
	font-size: 64px;
	line-height: 85.12px;
	font-weight: 800;
}

// HEADINGS
h1 {
	font-size: 32px;
	line-height: 42px;
	font-weight: 600;
}

h3 {
	font-size: 24px;
	line-height: 36px;
}

h4 {
	font-size: 20px;
	line-height: 26px;
}
