@use "../core/fonts";

.form-become-customer{
    &__content{
        &__header{
            padding: 32px;

            &__title{
                font-size: 48px;
                line-height: 60px;
                color: var(--brand-tertiary-500);
            }

            &__description{
                font-size: 16px;
                line-height: 24px;
                @extend .fw400;
                padding-top: 8px;
            }
        }

        form{
            padding: 0 32px;
        }

        &__form{
            display: flex;
            gap: 32px;
            

            & > div{
                width: 100%;
                flex-grow: 1;
            }

            .form-title{
                font-size: 20px;
                line-height: 30px;
                @extend .fw700;
                color: var(--brand-secondary-900);
            }

            .input-content{
                padding-top: 16px;

                label{
                    input{
                        @extend .inputs-md;
                    }
                }

                &__selector{
                    label{
                        padding-bottom: 0;
                    }
                    select{
                        width: 100%;

                        @extend .inputs-md;
                    }
                   
                }

                
            }

            .input-container{
                input{
                    width: 100%;
                }
            }
        }

        &__docs{
            color: var(--brand-secondary-700);
            font-size: 16px;
            line-height: 24px;
            &__title{
                @extend .fw700;
            }

            &__description{
                padding: 16px 0;

                span{
                    @extend .fw700;
                }
            }

            &__bottom{
                display: flex;
                align-items: center;

                &__add-button{
                    width: 100%;
                    flex-grow: 1;
                    border: 1px solid var(--brand-neutral-300);
                    border-radius: 8px;
                    max-width: 334px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    @extend .components-button-md;

                    .input-content{
                        label{
                            &::before{
                                padding-right: 12px;
                            }
                            span{
                                padding-right: 4px;
                            }
                        }
                    }
                }

                &__files{
                    padding: 0 32px;
        
                    ul{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
        
                        li{
                            background-color: var(--brand-neutral-100);
                            @extend .p-md;
                            @extend .fw500;
                            color: var(--theme-text-primary);
                            padding: 2px 12px;
                            border-radius: 24px;
                            display: flex;
                            gap: 6px;
        
                            .cross{
                                cursor: pointer;
                                display: block;
                                width: 24px;
                                height: 24px;
                                background-repeat: no-repeat;
                                background-size: cover;
                                background-image: url("/assets/images/close-cross.svg");
                            }
                        }
                    }
                }
            }
        }

        &__submit{
            padding-top: 12px;
            padding-bottom: 32px;
        }
    }


    .input-container{
        margin-bottom: 0;
    }
}

.reset_new_password{
    background: white;
    border-radius: 24px;
    padding: 40px;
}