@use "../core/fonts";

.request-subscription {
	padding-top: 24px;
	padding-bottom: 64px;

	&__heading {
		&__title {
			@extend .fw600;
			color: var(--theme-text-primary);
		}

		&__description {
			@extend .typhography-sm;
			@extend .fw500;
			max-width: 648px;
			padding-top: 16px;
			color: var(--theme-text-secondary);
		}
	}

	&__form {
		margin-top: 16px;
		padding: 32px;
		border: 1px solid var(--brand-neutral-300);
		border-radius: 8px;
		background-color: var(--theme-common-white);

		&__input {
			&__title {
				display: flex;
				align-items: center;
				gap: 8px;
				&__number {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					color: var(--theme-common-white);
					background-color: var(--brand-tertiary-500);
					border-radius: 50%;
					font-size: 16px;
					line-height: 16px;
					@extend .fw600;
				}

				h4 {
					@extend .fw600;
				}
			}

			&__content {
				padding-left: 34px;
				padding-top: 16px;

				display: flex;
				flex-direction: column;
				gap: 24px;
			}

			&__user {
				display: flex;
				:first-child {
					margin-right: 24px;
				}
			}

			&--name {
				.request-subscription__form__input__content {
					.input-content {
						label {
							max-width: 348px;
						}
					}
				}
			}
		}

		&__alert {
			padding-top: 16px;
			padding-left: 34px;
			max-width: 890px;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 40px;
		}

		&__submit-buttons {
			display: flex;
			gap: 24px;
		}
	}
}

.input-content {
	&__title {
		@extend .typhography-sm;
		@extend .fw500;
		color: var(--theme-text-secondary);
		padding-bottom: 6px;
	}
	label {
		display: flex;
		flex-direction: column;
		@extend .typhography-sm;
		@extend .fw500;
		color: var(--theme-text-secondary);
		max-width: 678px;

		input,
		textarea {
			&::placeholder {
				color: var(--theme-text-tertiary);
			}
		}

		input[type="text"],
		input[type="email"],
		input[type="tel"] {
			max-width: 677px;
			padding: 12px 16px;
			border: 1px solid var(--theme-text-tertiary);
			border-radius: 2px;
		}

		textarea {
			max-width: 677px;
			padding: 12px 16px;
			height: 134px;
			border: 1px solid var(--theme-text-tertiary);
			border-radius: 2px;
		}
	}

	&__radios {
		display: flex;
		gap: 24px;
		flex-wrap: wrap;

		input {
			display: none;
		}

		input[type="radio"]:checked + label {
			.input-content__radios__radio {
				outline: 3px solid var(--brand-tertiary-500);
				cursor: initial;
			}
		}

		&__radio {
			outline: 1px solid var(--theme-text-tertiary);
			border-radius: 4px;
			width: 280px;
			text-align: center;
			height: 100%;
			cursor: pointer;

			&__content {
				display: flex;
				flex-direction: column;
				align-items: center;
				padding: 24px;
			}

			&__title {
				@extend .p-md;
				@extend .fw600;
				color: var(--theme-text-primary);
				padding: 2px 12px;
				border-radius: 24px;

				&.developer {
					background-color: var(--brand-warning-100);
				}

				&.admin {
					background-color: var(--brand-primary-100);
				}
			}

			&__description {
				@extend .inputs-sm;
				color: var(--theme-text-secondary);
				padding-top: 4px;
			}

			&__button {
				padding-top: 16px;
				width: 100%;
			}
		}

		&--secondary {
			.input-content__radios__radio {
				width: 350px;
				text-align: left;

				&__content {
					align-items: flex-start;
					padding: 24px 16px;
				}

				&__title {
					@extend .p-md;
					@extend .fw600;
				}

				&__description {
					@extend .inputs-sm;
					@extend .fw400;
				}
			}

			input[type="radio"]:checked + label {
				.input-content__radios__radio {
					outline: 3px solid var(--brand-tertiary-500);
					cursor: initial;
				}
			}
		}
	}

	&__checkboxes {
		display: flex;
		gap: 24px;
		flex-wrap: wrap;
		&__checkbox {
			input {
				display: none;
			}

			input[type="checkbox"]:checked + label {
				outline: 2px solid var(--brand-tertiary-500);
				.input-content__checkboxes__checkbox__box {
					outline: 1px solid var(--brand-tertiary-300);

					&::after {
						background-image: url("/assets/images/icons/icon-checked-checkbox.svg");
						background-size: 14px 10px;
						display: inline-block;
						width: 14px;
						height: 10px;
						content: "";
					}
				}
			}

			label {
				max-width: 280px;
				background-color: var(--brand-neutral-050);
				outline: 1px solid var(--brand-secondary-500);
				border-radius: 4px;

				cursor: pointer;
			}

			&__content {
				display: flex;
				flex-direction: row;
				gap: 10px;
				padding: 16px;
				border-radius: 8px;

				&--admin {
					background-color: var(--brand-neutral-100);
					padding: 0;
					max-height: 108px;

					.input-content__checkboxes__checkbox__text {
						padding: 16px;
					}

					.input-content__checkboxes__checkbox__subscription {
						padding-top: 8px;
					}
				}
			}

			&__box {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 20px;
				height: 20px;
				outline: 1px solid var(--brand-secondary-500);
				border-radius: 4px;
			}
			&__text {
				&__title {
					@extend .inputs-md;
					@extend .fw600;
					color: var(--theme-text-primary);
				}
				&__description {
					@extend .inputs-sm;
					@extend .fw400;
					color: var(--theme-text-secondary);
					padding-top: 4px;
				}
			}
		}
	}

	&__selector {
		label {
			padding-bottom: 6px;
		}
		select {
			max-width: 677px;
			padding: 12px 16px;
			border: 1px solid var(--theme-text-tertiary);
			border-radius: 2px;
			background-image: url("/assets/images/icons/icon-selector.svg");
			background-repeat: no-repeat;
			background-size: 24px 23px;
			background-position-x: 99%;
			background-position-y: 10px;

			-moz-appearance: none; /* Firefox */
			-webkit-appearance: none; /* Safari and Chrome */
			appearance: none;
		}
	}

	&__checkbox {
		position: relative;
		label {
			margin: 0;
			flex-direction: row;
			gap: 10px;

			input {
				display: none;
				& ~ .checkmark {
					height: 20px;
					min-width: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					outline: 1px solid var(--theme-text-secondary);
					border-radius: 4px;
					&:after {
						content: "";
						position: absolute;
						display: none;
					}

					.check {
						background-image: url("/assets/images/check-checkbox.svg");
						background-size: cover;
						width: 13px;
						height: 10px;
						display: none;
					}
				}
				&:checked ~ .checkmark {
					background-color: var(--brand-tertiary-500);
					outline: 1px solid var(--brand-tertiary-500);
					.check {
						display: block;
					}
				}
			}

			p {
				@extend .p-md;
			}
		}
	}

	&__files {
		label {
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;

			@extend .components-button-md;
			@extend .fw500;
			color: var(--theme-text-primary);

			.format {
				color: var(--theme-text-secondary);
			}

			&::before {
				background-image: url("/assets/images/icons/icon-clip.svg");
				background-size: 24px 24px;
				display: inline-block;
				background-repeat: no-repeat;
				width: 24px;
				height: 24px;
				content: "";
			}
		}
		input {
			display: none;
		}
	}

	&.full {
		max-width: unset;
	}

	&.disabled {
		label {
			input {
				background-color: var(--brand-neutral-100);
				border: unset;
			}
		}
	}
}
