:root {
	// BRAND NEUTRAL
	--brand-neutral-050: #fbfcfe;
	--brand-neutral-100: #f0f4f8;
	--brand-neutral-200: #dde7ee;
	--brand-neutral-300: #cdd7e1;
	--brand-neutral-400: #9fa6ad;
	--brand-neutral-500: #636b74;
	--brand-neutral-600: #555e68;
	--brand-neutral-700: #32383e;
	--brand-neutral-800: #171a1c;
	--brand-neutral-900: #0b0d0e;

	// BRAND PRIMARY
	--brand-primary-050: #f1fffa;
	--brand-primary-100: #e4faf3;
	--brand-primary-200: #d9f4eb;
	--brand-primary-300: #b5e9d6;
	--brand-primary-400: #94dec3;
	--brand-primary-500: #47d7ac;
	--brand-primary-600: #40c39c;
	--brand-primary-700: #37a685;
	--brand-primary-800: #27775f;
	--brand-primary-900: #0b211a;

	// BRAND SECONDARY
	--brand-secondary-100: #f9fafd;
	--brand-secondary-200: #e2e5eb;
	--brand-secondary-300: #cbd0d9;
	--brand-secondary-400: #b4bbc8;
	--brand-secondary-500: #9da6b6;
	--brand-secondary-600: #6f7d92;
	--brand-secondary-700: #52627a;
	--brand-secondary-800: #3d4f6a;
	--brand-secondary-900: #13294b;

	// BRAND TERTIARY
	--brand-tertiary-050: #f1f4ff;
	--brand-tertiary-100: #d6e0ff;
	--brand-tertiary-200: #adc1ff;
	--brand-tertiary-300: #84a3ff;
	--brand-tertiary-400: #5b84ff;
	--brand-tertiary-500: #3366ff;
	--brand-tertiary-600: #2851cc;
	--brand-tertiary-700: #1e3d99;
	--brand-tertiary-800: #142865;
	--brand-tertiary-900: #0a1432;

	// BRAND WARNING
	--brand-warning-050: #fefaf6;
	--brand-warning-100: #fdf0e1;
	--brand-warning-200: #fce1c2;
	--brand-warning-300: #f3c896;
	--brand-warning-400: #ea9a3e;
	--brand-warning-500: #9a5b13;
	--brand-warning-600: #72430d;
	--brand-warning-700: #492b08;
	--brand-warning-800: #2e1b05;
	--brand-warning-900: #1d1002;

	// TEXT THEME
	--theme-text-icon: #13294b;
	--theme-text-primary: #13294b;
	--theme-text-secondary: #52627a;
	--theme-text-tertiary: #9da6b6;

	// THEME COMMON
	--theme-common-white: #ffffff;
	--theme-common-black: #000000;

	// DANGER
	--danger-500: #c41c1c;
	--danger-800: #430a0a;
}
