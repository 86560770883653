.api-management {
	&__form {
		.input {
			border: 1px solid #9da6b6;
		}
	}
	input[type="toogle"] {
		justify-content: space-between !important;
	}
}
