.stepper {
	background-color: var(--theme-common-white);
	border: 1px solid var(--brand-tertiary-500);
	border-radius: 8px;
	width: 100%;
	margin-bottom: 32px;

	&__content{
		padding: 16px 24px;
	}

	.steps {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		.step {
			width: 100%;
			display: flex;
			flex: 1;
			text-align: center;
			align-items: center;
			background-color: var(--theme-common-white);
			border: none;
			color: var(--brand-secondary-700);

			&.active {
				color: var(--brand-primary-500);

				.step-step {
					color: var(--brand-primary-500);
				}
			}

			.step-step {
				margin-right: 0.5rem;
				color: var(--brand-secondary-200);
			}

			.step-content {
				text-align: start;
			}

			.step-title {
				display: flex;
				align-items: center;
				margin-bottom: 0.25rem;
			}

			.step-description {
				font-size: 14px;
				color: var(--brand-secondary-700);
			}
		}
	}

	.stepper-title {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}

.icon-stepper {
	display: flex;
	width: 24px;
	height: 24px;
	background-image: url("/assets/images/icons/icon_forward.svg");
}

.icon-close {
	display: flex;
	width: 24px;
	height: 24px;
	background-image: url("/assets/images/icons/icon_close.svg");
	cursor: pointer;
}

button.step {
  cursor: default !important;
}
button.step{
  cursor: default;
}
.icon-close {
	cursor: pointer !important;
	background-color: transparent !important;
}
