.modal-delete-user{
    &__header{
        padding: 32px;
        background-color: var(--brand-neutral-100);
    }


    &__body{
        padding: 40px 32px;
    }

    &__buttons{
        border-top: 1px solid var(--brand-neutral-200);
        padding: 32px;
    }
}