@use "../core/fonts";

.app-list{
    width: 100%;
    border: 1px solid var(--brand-neutral-300);
    border-radius: 8px;
    background-color: var(--theme-common-white);

    &.tab-container{
        border: unset;

        .app-list__content{
            padding: unset;
        }
    }

    &__content{
        padding: 32px;

        &__item{
            &__name{
                @extend .fw600;
                color: var(--brand-secondary-900);
            }

            &__envs{
                display: flex;
                gap: 24px;
                padding-top: 16px;

                &__sub{
                    border: 1px solid var(--brand-secondary-500);
                    border-radius: 8px;
                    background-color: var(--brand-neutral-050);
                    width: 100%;
                    max-width: 394px;

                    cursor: pointer;

                    &__content{
                        padding: 16px;
                        

                        &__title{
                            @extend .p-md;
                            @extend .fw600;
                            color: var(--theme-text-primary);
                            text-transform: uppercase;
                            height: 33px;
                            border-bottom: 1px solid var(--brand-secondary-200);
                        }

                        &__apis{
                            &__api{
                                display: flex;
                                align-items: center;
                                height: 36px;
                                @extend .p-md;
                                @extend .fw500;

                                &__name{
                                    width: 184px;
                                    color: var(--theme-text-secondary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}