.pagination-component {
	flex-direction: row;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1rem;
	font-size: 14px;
	line-height: 21px;
	font-weight: 500;
	color: var(--theme-text-tertiary);

	&__button {
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;
	}

	&__select {
		border: none;
		font-weight: 500;
	}

	button{
		.icon-chevron{
			display: flex;
			width: 24px;
			height: 24px;
			background-image: url("/assets/images/icons/icon-chevronActive.svg");
			background-repeat: no-repeat;

			&.chevron-left{
				transform: rotate(180deg);
			}
		}

		&:disabled{
			.icon-chevron{
				background-image: url("/assets/images/icons/icon-chevronUnactive.svg");
				transform: rotate(180deg);
				&.chevron-left{
					transform: rotate(360deg);
				}
			}
		}
	}
}



