@use "../core/fonts";

.request-modification-tab-content {
	padding: 32px;
	border: 1px solid var(--brand-neutral-300);
	border-radius: 8px;
	background-color: var(--theme-common-white);

	&__sections {
		form {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}

		&__section {
			position: relative;

			&__title {
				h4 {
					@extend .fw600;
					color: var(--theme-text-primary);
				}
			}

			&__alert {
				padding-top: 16px;
			}
			&__description,
			&__credential {
				padding-top: 16px;
			}

			&__description {
				span {
					@extend .p-md;
					color: var(--theme-text-secondary);
				}

				&.sm {
					span {
						@extend .inputs-sm;
						@extend .fw500;
					}
				}
			}

			&__credential {
				display: flex;
				flex-direction: column;
				padding-bottom: 8px;
				&__title {
					@extend .typhography-sm;
					@extend .fw500;
					padding-bottom: 6px;
					color: var(--theme-text-secondary);
				}
				&__data {
					padding: 8px 0;
					@extend .p-md;
					@extend .fw500;
					color: var(--theme-text-primary);
					max-width: 604px;
					word-break: break-word;
					background-color: var(--brand-neutral-100);
					padding: 12px 16px;
					border-radius: 2px;
				}
			}

			&__checkbox {
				display: flex;
				gap: 24px;
				flex-wrap: wrap;
				padding-top: 10px;

				.input-content__checkboxes__checkbox__subscription {
					padding-top: 8px;
				}
			}

			&__inputs {
				&--api-info {
					display: flex;
					flex-direction: column;
					gap: 24px;
					padding-top: 40px;
				}

				&--notify-info {
					display: flex;
					flex-direction: column;
					gap: 24px;
					padding-top: 16px;
				}
			}

			&__button {
				padding-top: 16px;
				max-width: 218px;
			}

			&--user-no-customer {
				padding-bottom: 188px;
			}

			&__pending-advice {
				position: absolute;
				top: 0;
				height: 100%;
				display: flex;
				align-items: center;
				padding-left: 190px;
			}
		}

		&__documentation {
			display: flex;
			justify-content: flex-end;
			pointer-events: all;
		}

		&__submit-buttons {
			&__content {
				display: flex;
				gap: 24px;
			}
		}
	}

	&.not-editable {
		.request-modification-tab-content__sections {
			&__section {
				pointer-events: none;

				&--user-no-customer {
					pointer-events: all;
				}

				&--pending-request {
					pointer-events: all;
				}

				.input-content {
					&__checkboxes {
						&__checkbox {
							label {
								outline: unset;
								background-color: var(--brand-neutral-100);
							}
						}
					}

					&__radios {
						&__radio {
							outline: unset !important;
							background-color: var(--brand-neutral-050);
						}

						input[type="radio"]:checked + label {
							.input-content__radios__radio {
								outline: unset;
								background-color: var(--brand-neutral-100);
							}
						}
					}

					label {
						textarea,
						input[type="text"] {
							border: 1px solid var(--brand-neutral-100);
							background-color: var(--brand-neutral-100);
						}
					}
				}
			}
		}
	}
}
