@use "sass:math";

.mg {
	box-sizing: border-box;

	@for $i from 0 through 10 {
		$margin: false;
		$margin: math.div($i, 2) * 1rem; // Usamos math.div para la división

		&.mg-#{$i} {
			margin: $margin;
		}

		&.mg--t-#{$i} {
			margin-top: $margin;
		}
		&.mg--r-#{$i} {
			margin-right: $margin;
		}
		&.mg--b-#{$i} {
			margin-bottom: $margin;
		}
		&.mg--l-#{$i} {
			margin-left: $margin;
		}
	}
}
