$input-width-default: 394px;
$input-width-extrasmall: 200px;
$input-width-small: 250px;
$input-width-medium: 300px;
$input-width-large: 500px;
$input-width-extralarge: 700px;
$input-width-full: 100%;

$input-height-default: 44px;
$input-height-medium: 100px;
$input-height-large: 200px;
$input-height-extralarge: 200px;

@use "../core/fonts";

@mixin input-width($width) {
	width: $width;
}

@mixin input-height($height) {
	height: $height;
}

.input-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;

	&.input-checkbox{
		width: unset!important;
		position: relative;
		label {
			margin: 0;
			flex-direction: row;
			gap: 10px;

			input {
				display: none;
				& ~ .checkmark {
					height: 20px;
					min-width: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					outline: 1px solid var(--theme-text-secondary);
					border-radius: 4px;
					&:after {
						content: "";
						position: absolute;
						display: none;
					}

					.check {
						background-image: url("/assets/images/check-checkbox.svg");
						background-size: cover;
						width: 13px;
						height: 10px;
						display: none;
					}
				}
				&:checked ~ .checkmark {
					background-color: var(--brand-tertiary-500);
					outline: 1px solid var(--brand-tertiary-500);
					.check {
						display: block;
					}
				}
			}

			p {
				@extend .p-md;
			}
		}
	}

	.err{
		margin-top: 6px;
		color: var(--danger-500);
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		span{
			background-image: url("/assets/images/icons/icon-error.svg");
			background-repeat: no-repeat;
			background-position: 0;
			background-size: 24px;
			padding-left: 1.8em;
			margin-right: 0;
		}
	}

	label {
		display: block;
		margin-bottom: 5px;
	}

	label:empty {
		display: none;
	}

	input[type="text"],
	[type="password"],
	[type="date"],
	[type="tel"],
	[type="email"],
	textarea,
	select {
		width: $input-width-default;
		height: $input-height-default;
		padding: 8px;
		box-sizing: border-box;
		border: 0.2px solid var(--brand-secondary-500);
		border-radius: 4px;

		&:focus {
			outline: none;
		}

		&.input--extrasmall {
			@include input-width($input-width-extrasmall);
		}

		&.input--small {
			@include input-width($input-width-small);
		}

		&.input--medium {
			@include input-width($input-width-medium);
		}

		&.input--large {
			@include input-width($input-width-large);
		}

		&.input--full {
			@include input-width($input-width-full);
			@include input-height($input-height-large);
		}

		&.input--mediumlarge {
			@include input-width($input-width-full);
			@include input-height($input-height-extralarge);
		}

		&.input--extralarge {
			@include input-width($input-width-extralarge);
			@include input-height($input-height-extralarge);
		}

		&.input--mediumHeight {
			@include input-height($input-height-medium);
		}
	}

	select {
		border: none;
	}

	textarea {
		text-align: left;
		padding-top: 20px;
	}

	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 5px;
	}

	option {
		padding: 10px;
	}

	&__toggle {
		display: flex;
		gap: 20px;
		//justify-content: space-between;
		margin-bottom: 1rem;
	}

	&__radioTitle {
		text-transform: uppercase;
		padding: 0.4rem 1rem;
		border-radius: 8px;
	}
}

.input-container__radio {
	display: flex;
	align-items: center;
	gap: 10px; /* Espaciado entre el radio y la etiqueta */
}

.input-container__radio label {
	display: flex;
	align-items: center;
	text-align: center;
	padding: 2rem;
	border: 1px solid var(--brand-secondary-500);
	box-sizing: border-box;
	border-radius: 8px;
}

.input-container__radio input[type="radio"] {
	display: none;
}

.input-container__radio label [type="radio"]:checked {
	border-color: 1px solid var(--brand-tertiary-500);
}

.input-container__radio label[type="radio"]:checked::after {
	border-color: 1px solid var(--brand-tertiary-500);
}

.switch {
	position: relative;
	display: inline-block;
	width: 34px;
	height: 20px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: var(--theme-common-white);
	/* border-color: red; */
	outline: 1px solid var(--brand-primary-500);

	&::before {
		background-color: var(--brand-primary-500);
	}
}

input:checked + .slider:before {
	transform: translateX(14px);
}

input:disabled,
textarea:disabled,
select:disabled {
	background-color: var(--brand-neutral-100);
}

.slider.round {
	border-radius: 20px;
}

.slider.round:before {
	border-radius: 50%;
}
