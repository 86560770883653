.container{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 500px;
    margin-top: 200px;
    background: #fff;
    padding-bottom: 30px;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 30px;
}

.text{
    color: #3c009d;
    font-size: 48px;
    font-weight: 700;
}

.underline{
    width: 61px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
}

.inputs{
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 380px;
    height: 50px;
    background: #eaeaea;
    border-radius: 6px; 
}

.input img{
    margin: 0px 20px;
}

.input input{
    height: 50;
    width: 400px;
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 19px;
}

.forgot-password{
    padding-left: 62px;
    margin-top: 0px;
    color: #797979;
    font-size: 18px;
}

.forgot-password span{
    color: #4c00b4;
    cursor: pointer;
}

.submit-container{
    display: flex;
    gap: 30px;
    margin: 20px auto;
}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 39px;
    color: #fff;
    background: #4c00b4;
    border-radius: 50px;
    font-size: 19px;
    font-weight: 700;
    cursor: pointer;
}

.gray{
    background: #EAEAEA;
    color: #676767;
}