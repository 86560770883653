.Toastify__toast{
    background: var(--theme-common-white);
    color: var(--theme-common-black);

    &--success{
        background: var(--brand-secondary-900);
        color: var(--theme-common-white);

        .Toastify__toast-icon{
            
            background-image: url("/assets/images/icons/icon-toast-success.svg");
			background-repeat: no-repeat;
			background-position: 0;
			background-size: 24px;
			padding-left: .2em;
            height: 20px;

            svg{
                display: none;
            }
        }
    }

    &--error{
        background: var(--danger-800);
        color: var(--theme-common-white);

        .Toastify__toast-icon{
            
            background-image: url("/assets/images/icons/icon-toast-error.svg");
			background-repeat: no-repeat;
			background-position: 0;
			background-size: 20px;
			padding-left: .2em;
            height: 20px;

            svg{
                display: none;
            }
        }
    }

    &-body{
        align-items: unset;
        &>div{
            &:last-child{
                line-height: 22px;
            }
        }
    }
}