@use "../core/fonts";

.add-user-form {
	padding-top: 24px;

	&__form {
		border: 1px solid var(--brand-neutral-300);
		border-radius: 8px;
		margin-top: 16px;
		background-color: var(--theme-common-white);

		&__heading {
			&__title {
				color: var(--theme-text-primary);
			}
		}
		&__content {
			padding: 32px;
			display: flex;
			flex-direction: column;
			gap: 16px;

			&__title {
				color: var(--theme-text-primary);
			}

			&__row {
				display: flex;
				gap: 24px;
			}

			.input-container {
				label {
					@extend .typhography-sm;
					@extend .fw500;
					color: var(--theme-text-secondary);
				}
				input {
					border: 1px solid var(--brand-secondary-500);
				}
			}

			.input-content {
				.data {
					display: block;
					background: var(--brand-neutral-100);
					padding: 12px 16px;
					width: 362px;
					@extend .inputs-md;
					font-weight: 400;
					border: 1px solid var(--brand-secondary-500);
				}

				.aclaration {
					font-size: 14px;
					line-height: 19px;
					font-weight: 400;
					color: var(--theme-text-secondary);
				}
			}
			&__checkbox {
				display: flex;
				gap: 20px;
			}
		}

	}

	&__buttons {
		display: flex;
		gap: 24px;
	}
}

.add-user-organization{
	.add-user-form__form__content{
		padding: 0;
	}
}

.option-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 280px;
	border: 1px solid #9da6b6;
	border-radius: 8px;
	padding: 20px;
	cursor: pointer;
	transition: border 0.3s ease;
	text-align: center;
}

.option-box.selected {
	border: 2px solid #007bff;
}

.option-box p {
	font-size: 0.9em;
	color: #666;
}

.role-label {
	display: inline-block;
	padding: 4px 8px;
	border-radius: 12px;
	background-color: var(--brand-warning-100);
	color: var(--brand-warning-700);
	text-transform: uppercase;
	font-size: 12px;
}

.role-label.DEVELOPER {
	background-color: var(--brand-warning-100);
	color: var(--brand-warning-700);
}

.role-label.ADMIN {
	background-color: var(--brand-primary-200);
	color: var(--brand-primary-700);
}
