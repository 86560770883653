@use "../core/fonts";

.table-container {
	width: 100%;
	overflow-x: auto;
}

.table-component {
	width: 100%;
	border-collapse: collapse;
	display: flex;
	flex-direction: column;

	thead,
	tbody {
		display: flex;
		flex-direction: column;
	}

	thead {
		tr {
			display: flex;
			width: 100%;
		}

		th {
			background-color: var(--brand-secondary-100);
			border: none;
			text-align: left;
			font-weight: 100;
			padding: 16px 12px;
			flex: 1;
			box-sizing: border-box;
			min-width: 100px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            color: var(--theme-text-secondary);
			cursor: pointer;
            @extend .fw500;
		}
	}

	tbody {
		tr {
			display: flex;
			width: 100%;
			border-bottom: 1px solid var(--brand-neutral-200);
			align-items: center;
		
		}

		td {
			text-align: left;
			padding: 16px 12px;
			flex: 1;
			box-sizing: border-box;
			cursor: pointer;
			min-width: 100px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 16px;
			font-weight: 500;
			line-height: 24px;

			&.type{
				font-size: 14px;
				line-height: 21px;
			}
		}

		// Estilos para roles específicos
		.role-admin {
			display: inline-block;
			padding: 4px 8px;
			border-radius: 12px;
			background-color: var(--brand-primary-200);
			color: var(--brand-primary-700);
			text-transform: uppercase;
			font-size: 12px;
		}

		.role-developer {
			display: inline-block;
			padding: 4px 8px;
			border-radius: 12px;
			background-color: var(--brand-warning-100);
			color: var(--brand-warning-700);
			text-transform: uppercase;
			font-size: 12px;
		}
	}

	&__button {
		background: none;
		border: none;
		cursor: pointer;
		padding: 0;
	}

	.icon-more {
		display: flex;
		width: 24px;
		height: 24px;
		background-image: url("/assets/images/icons/icon-more.svg");
		background-repeat: no-repeat;
	}
}
