@use "../core/fonts";

.audit-details{
    &__status{
        font-size: 14px;
        display: flex;
    }

    &__reject-reason{
        padding-top: 8px;
    }

    &__type{
        p{
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            color: var(--theme-text-secondary);

            @extend .fw500;
        }
    }

    &__data{
        p{
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            color: var(--theme-text-secondary);

            @extend .fw500;
        }
    }

    .input-container {
        width: 290px;

        input[type="text"] {
            width: 100%;

            @extend .inputs-md;
            @extend .fw400;
            color: var(--theme-text-primary);
        }

        textarea {
            @extend .inputs-md;
            @extend .fw400;
            color: var(--theme-text-primary);
            width: 604px;
            height: 288px;
        }
    }

    &__info-full{
        .input-container{
            width: 100%;
        }
    }
}