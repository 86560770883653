.dropdown {
	position: absolute;
	background-color: white;
	border: 1px solid #ccc;
	padding: 10px;
	z-index: 1000;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		margin: 5px 0;
	}

	input {
		border: none;
		width: 100%;
	}
}
.info-legal ul {
    list-style: circle !important;
    padding-left: 20px !important;
    margin-bottom: 20px !important;
}
.info-legal section, .info-legal h3, .info-legal h4{
  margin-top: 30px !important;
}
.info-legal li{
  list-style: circle;
  margin-bottom: 20px !important;
  line-height: 26px;
}