@use "../core/fonts";

.api-subscription-state{
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 0;

   

    &.subscribed{
        .api-subscription-state__circle{
            background-color: var(--brand-primary-500);
            outline: 1px solid var(--brand-primary-500);
        }
    }
    &.pending{
        .api-subscription-state__circle{
            background-color: var(--brand-neutral-400);
        }
    }
    &.unsubcription{
        .api-subscription-state__circle{
            background-color: var(--brand-warning-400);
            outline: 1px solid var(--brand-warning-400);
        }
    }

    &.unsubscription_requested{
        .api-subscription-state__circle{
            background-color: var(--danger-500);
            outline: 1px solid var(--danger-500);
            
            &__less{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                height: 13px;
            }
        }
    }

    &.subscription_requested{
        .api-subscription-state__circle{
            background-color: var(--brand-primary-500);
            outline: 1px solid var(--brand-primary-500);
            
            &__plus{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                height: 16px;
            }
        }
    }

    &--admin{
        &.pending{
            .api-subscription-state__circle{
                background-color: var(--brand-primary-500);
                outline: 1px solid var(--brand-primary-500);

                &__plus{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 24px;
                    height: 16px;
                }
            }
        }
    }

    &__circle{
        width: 16px;
        height: 16px;
        background-color: transparent;
        border-radius: 50%;
        outline: 1px solid var(--brand-neutral-400);

        &__plus, &__less{
            color: var(--theme-common-white);
            display: none;
        }
    }

    &__text{
        @extend .inputs-sm;
        @extend .fw400;
        color: var(--theme-text-primary);
    }
}