@use "../core/fonts";

.admin-management {
	&__icon {
		display: flex;
		width: 24px;
		height: 32px;
		background-image: url("/assets/images/icons/icon-delete.svg");
		background-repeat: no-repeat;
	}
}

.add-admin{
	border: unset;
	padding: 0;

	.request-detail__information{
		flex-direction: column;

		.input-two{
			display: flex;
			gap: 24px;
		}

		.input-content{
			.data {
				display: block;
				background: var(--brand-neutral-100);
				padding: 12px 16px;
				width: 362px;
				@extend .inputs-md;
				font-weight: 400;
				border: 1px solid var(--brand-secondary-500);
			}

			.aclaration {
				font-size: 14px;
				line-height: 19px;
				font-weight: 400;
				color: var(--theme-text-secondary);
			}
		}

		.input-container{
			width: 394px;

			

			input{
				border: 1px solid var(--brand-secondary-500);
			}
		}
	}
}
