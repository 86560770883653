.request-management {
	&__actions {
		display: flex;
		gap: 22px;
		justify-content: space-between;
		height: 48px;

		.input-container {
			width: 290px;

			input[type="text"] {
				width: 100%;
			}
		}

		.input-content__selector {
			select {
				padding-right: 40px;
			}

			label {
				padding-bottom: unset;
			}
		}

		.input-daterange {
			height: 100%;
			input {
				height: 100%;
			}
		}

		.link-component {
			padding: 12px 24px;
		}
	}

	&__filters {
		display: flex;
		gap: 40px;
		align-items: center;

		ul {
			display: flex;
			gap: 8px;
			flex-wrap: wrap;
			li {
				button {
					padding: 2px 12px;
					background-color: var(--brand-neutral-100);
					border-radius: 24px;
				}
			}
		}
	}
}
.select-filter {
	padding: 0.8rem;
}
