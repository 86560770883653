@use "../core/fonts";

.subscribe-request-details{
    &__apis{

        &__title{
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            color: var(--theme-text-secondary);
        }

        &__content{
            display: flex;
            gap: 24px;
            padding-bottom: 16px;
        }
    }

    &__information-inputs{
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .input-container {
        width: 290px;

        input[type="text"] {
            width: 100%;

            @extend .inputs-md;
            @extend .fw400;
            color: var(--theme-text-primary);
        }

        textarea {

            @extend .inputs-md;
            @extend .fw400;
            color: var(--theme-text-primary);
        }
    }

    &--full{
        .input-container{
            width: 100%;
        }
    }
}