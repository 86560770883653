@use "sass:math";

.p {
	box-sizing: border-box;

	@for $i from 0 through 10 {
		$padding: false;
		$padding: math.div($i, 2) * 1rem; // Usamos math.div para la división

		&.p-#{$i} {
			padding: $padding;
		}

		&.p--t-#{$i} {
			padding-top: $padding;
		}
		&.p--r-#{$i} {
			padding-right: $padding;
		}
		&.p--b-#{$i} {
			padding-bottom: $padding;
		}
		&.p--l-#{$i} {
			padding-left: $padding;
		}
	}
}
