@use "../core/fonts";

.link-component {
	@extend .p-md;
	@extend .fw500;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 0 8px;
	border: unset;
	background: transparent;
	cursor: pointer;
	padding: 9.5px 8px;
	border-radius: 4px;

	text-decoration: none;
	color: unset;

	&__icon {
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;

		display: none;

		&.icon {
			display: block;
		}
	}

	&__text {
		white-space: nowrap;

		p{
			color: var(--theme-text-primary);
		}
	}

	&__counter {
		margin-left: 8px;
		background-color: var(--brand-primary-500);
		color: var(--theme-common-black);
		border-radius: 50%;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		min-width: 24px;
		height: 24px;
		font-size: 14px;
	}

	&__image {
		background-color: var(--brand-neutral-100);
		border-radius: 24px;
		width: fit-content;
		margin-right: 2rem;
	}

	&.filled {
		background-color: var(--brand-primary-500);
		color: var(--theme-common-black);

		&.tertiary-100 {
			background-color: var(--brand-tertiary-100);
		}
	}

	&.border-tertiary-500 {
		outline: 1px solid var(--brand-tertiary-500);
	}

	&.text-tertiary-500 {
		color: var(--brand-tertiary-500);
	}

	&.border-brand-neutral-300 {
		outline: 1px solid var(--brand-neutral-300);
	}

	&.bg-pending{
		background-color: #F9B959;
	}
}

.icon-chat {
	background-image: url("/assets/images/icons/icon-chat.svg");
}
.icon-support {
	background-image: url("/assets/images/icons/icon-support.svg");
}
.icon-dev {
	background-image: url("/assets/images/icons/icon-dev.svg");
}
.icon-spark {
	background-image: url("/assets/images/icons/icon-spark.svg");
}
.icon-spark-white {
	background-image: url("/assets/images/icons/icon-spark-white.svg");
}
.icon-check {
	background-image: url("/assets/images/icons/icon-check.svg");
}
.icon-audit {
	background-image: url("/assets/images/icons/icon-audit.svg");
}
.icon-organizations {
	background-image: url("/assets/images/icons/icon-organizations.svg");
}
.icon-settings {
	background-image: url("/assets/images/icons/icon-settings.svg");
}
.icon-users {
	background-image: url("/assets/images/icons/icon-users.svg");
}
.icon-download {
	background-image: url("/assets/images/icons/icon-download.svg");
}
.icon-external {
	background-image: url("/assets/images/icons/icon-external.svg");
}
.icon-clock {
	background-image: url("/assets/images/icons/icon-clock.svg");
}
.icon-pending {
	background-image: url("/assets/images/icons/icon-pending.svg");
}

