@use '../core/fonts';

.homepage{
    &--app-subscriptions{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 56px;

        &__title{
            @extend .fw600;
            color: var(--theme-text-primary);
            padding-top: 56px
        }

        &__subtitle{
            @extend .p-md;
            @extend .fw500;
            padding-top: 8px;
            color: var(--theme-text-secondary);
        }

        &__button{
            padding-top: 32px;
        }

        &__app-list{
            width: 100%;

            &__content{
                display: flex;
                align-items: center;
                justify-content: space-between;

                &__text{
                    &__title{
                        @extend .fw600;
                        color: var(--theme-text-primary);
                    }
                    &__description{
                        @extend .p-md;
                        @extend .fw500;
                        color: var(--theme-text-secondary);
                        padding-top: 16px;
                    }
                }
            }

            &__list{
                padding-top: 32px;
            }
        }
    }
}