@use '../core/fonts';

.reject-request{
    &__header{
        padding: 32px;
        background-color: var(--brand-neutral-100);
        &__title{
            @extend .fw600;
            color: var(--brand-secondary-900);
        }
    }

    &__form{
        gap: 24px;
        padding-bottom: 32px;

        &__section{
            padding: 0 32px;
        }

        &__title{
            @extend .p-md;
            @extend .fw700;
        }

        &__input-content{
            
            padding: 40px 0;
            display: flex;
            gap: 16px;
            flex-direction: column;
            &--row{
                flex-direction: row;
                gap: 24px;
            }

            .input-content{
                flex-grow: 1;
                max-width: 306px;

               &__title{
                padding-bottom: 24px;
               }
            }
  
        }


        &__submit{
            padding: 0 32px;
            padding-top: 24px;
            padding-bottom: 0;
            border-top: 1px solid var(--brand-neutral-200);
            display: flex;
            align-items: center;
            gap: 24px;
        }

    }
    
}