@use '../core/fonts';

.advice-modal{
    &__header{
        padding: 32px;
        background-color: var(--brand-neutral-100);
        &__title{
            @extend .fw600;
            color: var(--brand-secondary-900);
        }
    }

    &__advice{
        padding: 40px 32px;

        &__content{
            @extend .p-md;
            @extend .fw500;
            padding: 16px;
            background-color: var(--brand-warning-100);
            border-radius: 4px;
            color: var(--brand-warning-700);

            span{
                @extend .fw700;
            }
        }
    }

    &__buttons{
        border-top: 1px solid var(--brand-neutral-300);
        padding: 32px;
        display: flex;
        gap: 24px;
    }
}