.tabs {
	width: 100%;
}

.tabs-header {
	display: flex;
	// border-bottom: 2px solid #ccc;
}

.tab {
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.tab.active {
	border-bottom: 2px solid #000000;
	font-weight: 500;
}

.tab-content {
	display: none;
}

.tab-content.active {
	display: block;
}
