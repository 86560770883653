.modal-component {
	position: relative;
	background: white;
	border-radius: 8px;
	overflow: hidden;
	width: 100%;
	max-height: 96%;
	overflow-y: scroll;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none;
	max-width: 94vw;
	// padding: 2rem;

	.close-button {
		top: 32px;
	}
	&.medium {
		max-width: 700px;
		padding: 2rem;
	}

	&.lg {
		max-width: 700px;
	}

	&.lg2 {
		width: 920px;
	}

	&.xl {
		max-width: 1096px;
	}

	&.xxl {
		max-width: 1280px;
	}

	&__backdrop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	// &__content {
	// 	padding: 20px;
	// }
}

.close-button {
	position: absolute;
	right: 32px;
	background: transparent;
	border: none;
	font-size: 20px;
	cursor: pointer;

	.cross {
		width: 32px;
		height: 32px;
		background-repeat: no-repeat;
		background-image: url("/assets/images/close-cross.svg");
	}
}
