@use "../core/fonts";

.user-settings {
	&__password {
		display: flex;
		flex-direction: column;

		button {
			width: max-content;
			margin-bottom: 1rem;
		}
	}

	&__buttons {
		width: 40%;
		display: flex;
		justify-content: space-between;
	}

	.flex-content{
		display: flex;
		gap: 24px;
	}

	.input-content{
		.data, input{
			display: block;
			background: var(--brand-neutral-100);
			padding: 12px 16px;
			width: 362px;
			@extend .inputs-md;
			font-weight: 400;
			min-height: 20px;
		}

		.aclaration{
			font-size: 14px;
			line-height: 19px;
			font-weight: 400;
			color: var(--theme-text-secondary);
		}
		
	}

	.input-container{
		width: 396px;
		input{
			width: 100%;
		}
	}
}
