@use "../core/fonts";

.edit-users {
	&__content {
		display: flex;
	}

	&__admin {
		display: inline-block;
		padding: 4px 8px;
		border-radius: 12px;
		text-transform: uppercase;
		background-color: var(--brand-primary-200);
		color: var(--brand-primary-700);
	}

	&__developer {
		display: inline-block;
		padding: 4px 8px;
		border-radius: 12px;
		text-transform: uppercase;
		background-color: var(--brand-warning-100);
		color: var(--brand-warning-700);
	}

	&__remove {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&--text {

			&__title{
				color: var(--theme-text-primary);
			}

			&__subtitle{
				@extend .p-md;
				@extend .fw500;
				color: var(--theme-text-secondary);
			}

			p {
				max-width: 648px;
			}
		}

		&--btn {
			display: flex;
			align-items: center;
			gap: 12px;
			color: var(--danger-500);

			&__icon{
				display: flex;
				width: 18px;
				height: 18px;
				background-image: url("/assets/images/icons/icon-remove.svg");
				background-repeat: no-repeat;
			}
		}
	}

	&__icon {
		display: flex;
		width: 24px;
		height: 24px;
		background-image: url("/assets/images/icons/icon-remove.svg");
		background-repeat: no-repeat;
	}
}
