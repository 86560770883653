@use '../core/fonts';

.form-support{
    &__header{
        padding: 32px;
        background-color: var(--brand-neutral-100);
        &__title{
            @extend .fw600;
            color: var(--brand-secondary-900);
        }
    
        &__description{
            padding-top: 8px;
            @extend .typhography-sm;
            @extend .fw500;
            color: var(--theme-text-secondary);

            a{
                color: var(--brand-tertiary-500);
                text-decoration: unset;
            }
        }
    }

    &__form{
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 32px 0;

        &__section{
            padding: 0 32px;
        }

        &__title{
            @extend .p-md;
            @extend .fw700;
        }

        &__input-content{
            
            padding-top: 16px;
            display: flex;
            gap: 16px;
            flex-direction: column;
            &--row{
                flex-direction: row;
                gap: 24px;
            }

            .input-content{
                flex-grow: 1;
                max-width: 306px;

                &__selector{
                    select{
                        width: 100%;
                    }
                   
                }
            }
  
        }

        &__files{
            padding: 0 32px;
            padding-bottom: 8px;

            ul{
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                li{
                    background-color: var(--brand-neutral-100);
                    @extend .p-md;
                    @extend .fw500;
                    color: var(--theme-text-primary);
                    padding: 2px 12px;
                    border-radius: 24px;
                    display: flex;
                    gap: 6px;

                    .cross{
                        cursor: pointer;
                        display: block;
                        width: 24px;
                        height: 24px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-image: url("/assets/images/close-cross.svg");
                    }
                }
            }
        }

        &__submit{
            padding: 0 32px;
            padding-top: 24px;
            padding-bottom: 0;
            border-top: 1px solid var(--brand-neutral-200);
            display: flex;
            align-items: center;
            gap: 24px;
        }

    }
    
}
