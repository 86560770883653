@use '../core/fonts';

.back-button{
    display: flex;
    align-items: center;
    gap: 6px;
    height: 32px;
    cursor: pointer;
    width: fit-content;
    &__icon{
        width: 14px;
        height: 14px;
        background-repeat: no-repeat;
        background-image: url("/assets/images/icons/icon-back.svg");
    }
    &__text{
        @extend .components-button-sm;
        @extend .fw500;
        color: var(--brand-tertiary-500);
    }
}