@use "../core/fonts";

.create-organization {
	&__information {
		display: flex;
		justify-content: space-between;
	}

	&__content {
		display: flex;
		justify-content: space-between;
	}

	&__buttons {
		display: flex;
		gap: 24px;
	}

	&__form{
		label{
			// width: 100%;
		}
	
		select{
			width: 100%;
		}
	}

	
}

.create-organization{
	&__form{
		&__content{
			max-width: 394px;
		}
	}
	
}

.edit-organization {
	padding-bottom: 64px;

	.input-container{
		input, select{
			color: var(--theme-text-icon);
			opacity: 1;

		}
	}

	&__heading {
		&__title {
			@extend .fw600;
			color: var(--theme-text-primary);
		}

		&__description {
			@extend .typhography-sm;
			@extend .fw500;
			max-width: 648px;
			padding-top: 16px;
			color: var(--theme-text-secondary);
		}
	}

	&__form {
		padding: 0px 32px 32px 32px;
		border: 1px solid var(--brand-neutral-300);
		border-radius: 0 0 8px 8px;
		background-color: var(--theme-common-white);

		&__input {
			&__title {
				display: flex;
				align-items: center;
				gap: 8px;
				&__number {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 24px;
					height: 24px;
					color: var(--theme-common-white);
					background-color: var(--brand-tertiary-500);
					border-radius: 50%;
					font-size: 16px;
					line-height: 16px;
					@extend .fw600;
				}

				h4 {
					@extend .fw600;
				}
			}

			&__content {
				padding-left: 34px;
				padding-top: 16px;

				display: flex;
				flex-direction: column;
				gap: 24px;
			}

			&__user {
				display: flex;
				:first-child {
					margin-right: 24px;
				}
			}

			&--name {
				.request-subscription__form__input__content {
					.input-content {
						label {
							max-width: 348px;
						}
					}
				}
			}
		}

		&__alert {
			padding-top: 16px;
			padding-left: 34px;
			max-width: 890px;
		}

		form {
			display: flex;
			flex-direction: column;
			gap: 40px;
		}

		&__submit-buttons {
			display: flex;
			gap: 24px;
		}
	}
}
