@use "../core/fonts";

button {
	background-color: var(--theme-common-white);
}

.button-component {
	@extend .components-button-lg;
	@extend .fw500;
	width: fit-content;
	display: flex;
	padding: 15px 24px;
	background-color: var(--brand-tertiary-500);
	color: var(--theme-common-white);
	border-radius: 4px;
	outline: none;
	border: none;
	white-space: nowrap;

	&__icon {
		margin-right: 8px;
		align-items: center;
	}

	&--warning {
		color: var(--brand-warning-500);
		border: 1px solid var(--brand-warning-300);
		background-color: var(--theme-common-white);
	}

	&--reject {
		color: var(--danger-500);
		background-color: var(--theme-common-white);
		outline: 1px solid var(--danger-500);
	}

	&--neutral {
		color: var(--brand-secondary-900);
		border-color: var(--brand-secondary-900);
		background-color: var(--brand-neutral-050);
		outline: 1px solid var(--brand-neutral-300);
	}

	&--bg--warning-500{
        background-color: var(--brand-warning-500);
		background: #F9B959;
    	color: #492B08;
    }

	&--bg-danger-500{
        background-color: var(--danger-500);
    }
	&--warning-500 {
		background-color: var(--brand-warning-500);
	}

	&--remove {
		padding: 0.2rem;
		background-color: white;
		color: var(--danger-500);
		border: none;
	}

	&--danger {
		color: var(--theme-common-white);
		background-color: var(--danger-500);
		outline: 1px solid var(--danger-500);
	}
}

.pair-buttons {
	max-width: 350px;
	display: flex;
	gap: 24px;
}

.brand__hover:hover{
	background-color: #00CFBE;
}