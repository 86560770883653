@use "../core/fonts";


.email-configuration {

    h2{
        font-weight: 600;
        color: var(--theme-text-primary);
    }

    p{
        @extend .p-md;
        font-weight: 500;
        color: var(--theme-text-secondary);
    }

	&__add {
		display: flex;
		gap: 20px;
		align-items: center;

		&__button{
			flex-grow: 1;
			min-width: 210px;
			max-width: 210px;
		}
	}

	&__emails {
		display: flex;
		flex-wrap: wrap;
		max-width: 972px;
		align-items: center;
        gap: 1rem;
	}

	&__user {
        @extend .p-md;
        font-weight: 500;
		display: flex;
		align-items: center;
		background-color: var(--brand-neutral-100);
		padding: 2px 12px;
		border-radius: 24px;
		width: fit-content;
	}

	&__error {
		color: var(--danger-500);
	}
}