@use "../core/fonts";

.alert-component {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 16px;
	border-radius: 4px;
	&__text {
		@extend .p-md;
		@extend .fw500;
	}

	&__icon {
		width: 24px;
		height: 24px;
		background-repeat: no-repeat;

		display: none;

		&.icon {
			display: block;
		}
	}

	&.bg--brand-neutral-100 {
		background-color: var(--brand-neutral-100);
	}

	&.bg--brand-warning-100 {
		background-color: var(--brand-warning-100);
	}

	&.bg--brand-assurance-100 {
		background-color: var(--brand-primary-100);
	}
}

.icon-info {
	background-image: url("/assets/images/icons/icon-info.svg");
}

.icon-clock {
	background-image: url("/assets/images/icons/icon-clock.svg");
}
