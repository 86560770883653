@use "../core/fonts";

.request-modification{
    padding-top: 24px;
	padding-bottom: 64px;

    &__heading {
		&__title {
			@extend .fw600;
			color: var(--theme-text-primary);
		}
	}

    &__tabs{
        display: flex;
        padding-top: 16px;
        padding-left: 8px;

        &__tab{
            @extend .p-md;
            @extend .fw500;
            padding: 8px 16px;
            list-style-type: none;
            cursor: pointer;

            &.active{
                border-bottom: 2px solid var(--brand-secondary-900);
                margin-bottom: -2px;
                cursor: initial;
            }
        }
    }

    &__delete{
        
        margin-top: 32px;
        padding: 32px;
        border: 1px solid var(--brand-neutral-300);
        border-radius: 8px;
        background-color: var(--theme-common-white);
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__text{
            &__title{
                h4{
                    @extend .fw600;
                    color: var(--theme-text-primary);
                }
            }

            &__description{
                @extend .p-md;
                color: var(--theme-text-secondary);
                padding-top: 16px;
                max-width: 648px;
            }
        }

        &__button{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;

            &__icon{
                width: 28px;
                height: 28px;
                background-repeat: no-repeat;
                background-image: url("/assets/images/icons/icon-delete.svg");
            }

            &__text{
                @extend .components-button-lg;
                @extend .fw500;
                color: var(--danger-500);
            }
        }
    }
}