@use "../core/fonts";


.welcome-modal{
    

    &__header{
        padding: 32px;
        padding-bottom: 16px;
        &__title{
            font-size: 32px;
            font-weight: 300;
            line-height: 48px;
            color: var(--brand-secondary-900);

            span{
                font-size: 44px;
                line-height: 90px;
                color: var(--brand-tertiary-500);
            }
        }
    }


    &__body{
        padding: 16px 32px;

        &__t1{
            h4{
                @extend .fw500;
                color: var(--theme-text-primary);
            }
        }
        &__t2{
            padding-top: 16px;
            @extend .p-md;
            color: var(--theme-text-secondary);
        }
    }

    &__button{
        padding: 32px;
    }
}