.search-container {
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	padding-left: 16px;
	gap: 8px;
	max-width: 390px;
	border: 1px solid var(--brand-secondary-500);
	border-radius: 2px;

	.input-container {
		display: flex;
		align-items: center;
		margin-bottom: 0;

		input {
			padding: 0;
			height: fit-content;
		}
	}
}
.search-input {
	border: none !important;
	padding-left: 2rem;
}

.icon-search {
	width: 20px;
	height: 20px;
	fill: #ccc;
	background-image: url("../../assets/images/icons/icon-search.svg");
	background-repeat: no-repeat;
}

.search-input:focus {
	outline: none;
	border-color: #007bff;
}
