@use "../core/fonts";

.menu-logged {
	position: relative;
	&__avatar {
		width: 38px;
		height: 38px;
		border-radius: 50%;
		background-color: var(--theme-text-icon);
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&__letter {
			font-size: 20px;
			line-height: 26px;
			font-weight: 600;
			color: var(--theme-common-white);

			&--admin {
				color: var(--brand-secondary-900);
			}
		}

		&--admin {
			background-color: var(--brand-primary-500);
		}
	}

	&__menu {
		position: absolute;
		top: 48px;
		right: 0;
		background-color: var(--theme-common-white);
		-webkit-box-shadow: 0px 0px 2px 0px rgba(199, 199, 199, 1);
		-moz-box-shadow: 0px 0px 2px 0px rgba(199, 199, 199, 1);
		box-shadow: 0px 0px 2px 0px rgba(199, 199, 199, 1);
		border-radius: 8px;
		transform-origin: top;
		transform: scaleY(0);
		transition: all 0.2s ease;
		z-index: 100;
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; 

		&.active {
			transform: scaleY(1);
		}

		&__item {
			cursor: pointer;

			&:hover{
				background-color: var(--brand-neutral-100);
			}
		}

		&__user {
			min-width: 230px;
			padding: 24px;
			background-color: var(--brand-neutral-100);
			/* padding-top: 6px; */
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			cursor: initial;
			
			&__name {
				@extend .menu-item-md;
				@extend .fw600;

				max-width: 230px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&__email {
				@extend .menu-item-sm;
				@extend .fw400;
			}
		}

		&__settings {
			padding: 16px 24px;
		}

		&__sign-out {
			padding: 16px 24px;
			color: var(--danger-500);
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}

	&__bg {
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		pointer-events: none;

		&.active {
			pointer-events: all;
		}
	}
}
