.info-legal{
    padding-top: 40px;

    ul li{
        margin-bottom: 20px;
    }

    .legalintro{
        .title-legal{
            color: var(--theme-text-icon);
            font-weight: 200;
        }
        .text-legal{
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 20px;
            font-weight: 400;
            line-height: 26px;
            

            a{
                color: rgb(0, 0, 238);
                // text-decoration: unset;
            }
        }
        section{
            margin-top: 30px;
        }
        table{
            font-size: 14px;
            line-height: 20px;
            a{
                color: rgb(0, 0, 238);
                text-decoration: unset;
            }

            ul{
                list-style: initial;
                padding-left: 30px;
            }
        }
    }
}
.title-int-legal{
    font-size: 16px;
    font-weight: bolder;
}
.info-legal .text-legal li{
  list-style: circle;
  margin-bottom: 20px;
}
.info-legal ul {
    list-style: circle !important;
    padding-left: 20px !important;
    margin-bottom: 20px !important;
}
.info-legal section, .info-legal h3, .info-legal h4{
  margin-top: 30px !important;
}
.info-legal li{
  list-style: circle;
  margin-bottom: 20px !important;
  line-height: 26px;
}